// a very basic grid system
// ======================================================================

// use like this:
// <div class="grid">
//  <div class="grid__column"> Your content </div>
//  <div class="grid__column"> Your content </div>
// </div>

// The grids by default try to put everything stacked on each other
// on sizes below the l-breakpoint, and columns after that
// you can throw grids into each other to create custom layouts

.grid {
  @include spacing(l -1);
  @include spacing-inner(a 0);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none; // if applied on a list, remove list-styles

  // try to fit everything into a row on larger displays, in
  // effect making columns out of every grid__column
  @include mediaquery(l) {
    flex-direction: row;
  }
}

.grid__column {
  @include spacing-inner(l 1);
  flex: 1;
}

// custom grid, this one starts 'columnizing' at ~600px width
// for 2 elements, at around 900px for 3 elements etc. pp.
// ======================================================================

.grid--custom {
  flex-direction: row;

  > .grid__column {
    flex: 1 0 300px;
  }
}
