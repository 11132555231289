// default link styling
// ======================================================================

a {
  text-decoration: none;

  @include attention {
    text-decoration: underline;
  }
}
