// default vertical rhythm / margin-bottom spacing
// ======================================================================

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
hr,
table,
fieldset,
figure,
pre,
.rhythm,
%rhythm {
  @include spacing;
}

