// base styles for form elements, fieldsets, labels, inputs etc.
// ======================================================================

// default transparent background for all form elements
button,
input,
select,
textarea {
  background-color: transparent;
}

// default styles for text-input forms fields
[type='text'],
[type='tel'],
[type='email'],
[type='search'],
[type='number'],
[type='password'],
select,
textarea {
  @include spacing-inner(t 1/4, r 1/2, b 1/4, l 1/2);
  @include border-color(border);
  width: 100%;
  border: 1px solid;
  border-radius: 0;
  appearance: none; // no rounded inputs etc.

  @include attention {
    @include border-color(main);
  }
}

