// buttons
// ======================================================================

// on the how and why of @extend, read:
// http://csswizardry.com/2014/11/when-to-use-extend-when-to-use-a-mixin/

$border-margin: 5px;

// default button styles for every button
%button {
  display: inline-block;
  cursor: pointer;
}

.button {
  @extend %button;
  @include padding(v $border-margin, h $border-margin*3);
  @include background-color(background-button);
  @include color(default);
  @include font-size(h3);
  box-sizing: border-box;
  position: relative;
  appearance: none;
  border: 0 none;
  border-radius: 100px;
  min-width: 170px;
  text-align: center;

  &::before {
    @include margin(a $border-margin);
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: calc(100% - #{2*$border-margin});
    height: calc(100% - #{2*$border-margin});
    border: map-get($colors, main) 1px dashed;
    border-radius: 100px;
  }

  &:hover {
    text-decoration: none;
    border-bottom: 0 none;
  }

  &.prev-link,
  &.next-link,
  &.overview-link {
    @include font-size(h2);
    min-width: 20px;

    @include mediaquery(l) {
      min-width: 170px;
    }

    svg {
      @include margin(t -5px);
      width: 16px;
      height: 16px;
      stroke: map-get($colors, main);
    }

    span {
      display: none;

      @include mediaquery(l) {
        display: inline;
      }
    }
  }
}

