// wrapper styles
// ======================================================================

.wrapper {
  @include center;
  @include padding(t 5, b 1, h 15px);
  max-width: $width-max;

  @include mediaquery(l) {
    @include padding(t 2, l $content-indent);
  }
}

.wrapper__main {
  @include padding(t $height-header-mobile, b $height-footer);
  width: 100%;
  min-height: 100%;

  @include mediaquery(menubreak) {
    @include padding(t $height-header, b $height-footer);
  }
}
