// typographic stuff and general typographic related helper classes
// ======================================================================

.link-decorated {
  @include border-color(main);
  border: 2px dashed;
}

.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
