// default headings h1 - h6
// ======================================================================

h1 {
  @include z-index(feet);
  @include font-size(h1);
}

h2 {
  @include font-size(h2);
}

h3 {
  @include font-size(h3);
}

h4 {
  @include font-size(h4);
}

h5 {
  @include font-size(h5);
}

h6 {
  @include font-size(h6);
}
