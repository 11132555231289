// base unit definition and width/heights for other stuff
// ======================================================================

// base unit - default font-size * line-height in rem and half/double
$base-line-height: map-get(map-get($font-config, default), line-height);
$base-font-size: map-get($font-sizes, default);

$base-unit-in-px: $base-font-size * $base-line-height;
$base-unit: 1rem * $base-line-height;
$base-half: $base-unit / 2;
$base-double: $base-unit * 2;


// globally used width and height declarations
// ======================================================================

$width-max: 960px;
$width-special: 2em;

$height-something: 1000px;
$height-header: 150px;
$height-header-mobile: 110px;
$height-footer: 250px;

$content-indent: 105px;

$size-roundbutton: 40px;

// etc. pp.


// overrides and customization
// ======================================================================
// if you want to customize any of the values maps and values that
// are set by default in the later to be included tools, mixins and
// function (such as z-indexes) please add them here, before including
// the actual mixins, for example, override the z-indexes map from
// frckl-tools/_z-index.scss

// $z-indexes: (
//   whale: 1000,
//   elephant: 900,
//   tiger: 800,
//   dog: 700,
//   cat: 700,
//   mouse: 500,
//   worm: -1
// );

// transition durations
$duration-menu-toggle: 0.5s;
