.eyecatcher-wrap {
  @include padding(a $header-margin);
  @include background-color(main);
  position: relative;
  box-shadow: 0 0 15px $color-black;
  width: 100%;

  .eyecatcher {
    @include padding(t $header-margin, r 2*$header-margin, b $header-margin, l $header-margin);
    @include color(bordercolor-eyecatcher);
    border: 2px dashed map-get($colors, bordercolor-eyecatcher);
    width: auto;

    .close {
      @include z-index(sky);
      @include absolute(t $header-margin, r ($header-margin+7));
      font-size: 22px;
    }
  }

  @include mediaquery(l) {
    @include absolute(l 50%);
    @include margin(t $header-margin, r $header-margin, b $header-margin, l (-($width-max/3)+$header-margin));
    width: auto;
  }

  @include mediaquery(xxl) {
    @include margin(t $header-margin, r $header-margin, b $header-margin, l (-($width-max/2)+$header-margin));
  }
}
