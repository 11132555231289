// base styles for html, body and other global elements
// ======================================================================

html {
  @include color(default);
  @include background-color(background);
  font-size: $base-font-size; // as reference for rem
  height: 100%;
}

body {
  @include font(default);
  @include background-color(background);
  height: 100%;

  @include mediaquery(print) {
    * {
      display: none;
    }

    &::after {
      content: 'Please, do not waste paper by printing webpages.';
    }
  }
}

// text selection styles
::selection {
  @include background-color(main);
  @include color(inverted);
  text-shadow: none;
}
