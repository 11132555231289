// content styles
// ======================================================================

body {
  @include font-size(textmobile);

  @include mediaquery(l) {
    @include font-size(default);
  }
}

p {
  @include margin(b 0.5);

  &:last-child {
    @include margin(b 0);
  }
}

a {
  @include color(action);

  @include attention {
    text-decoration: none;
    border-bottom: map-get($colors, action) 1px dashed;
  }
}

h1 {
  @include margin(b 1);
  letter-spacing: -0.03em;
}

h2 {
  @include margin(b 0.75);
  letter-spacing: -0.03em;
}

h3 {
  @include margin(b 0.5);
}

.banner-image {
  // @include margin(b 0);
  @include background-color(main);
  width: 100%;
  height: 200px;
  overflow: hidden;

  @include mediaquery(l) {
    height: 300px;
  }

  @include mediaquery(xxl) {
    height: 450px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.section {
  @include padding(v 1);
  background-position: bottom right;
  background-repeat: no-repeat;

  @include mediaquery(l) {
    @include padding(v 2);
  }

  &--member {
    @include background-color(background-member);

    @include mediaquery(l) {
      background-image: url('../img/trees-bg-middlesection.svg');
      background-position: 95% bottom;
    }
  }

  &--donation {
    @include background-color(background-donation);

    @include mediaquery(l) {
      background-image: url('../img/trees-bg-bottomsection.svg');
    }
  }

  &--light {
    @include margin(b 1.5);
    @include padding(a 0);
  }

  &__content {
    @include margin(v 0, h auto);
    @include padding(t 3, h 15px);
    max-width: $width-max;


    @include mediaquery(l) {
      @include padding(t 0, l $content-indent);
    }
  }

  &--promoter &__content {
    @include padding(t 0);
  }

  figure {

    @include mediaquery(m) {
      @include margin(t 0.3, r 1);

      float: left;
      width: 50%;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

}

.polaroid {
  @include padding(a 5px);
  @include background-color(background);
  position: relative;
  display: inline-block;
  box-shadow: $image-shadow;

  &::after {
    content: ' ';
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: map-get($colors, background) 1px dashed;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.baguettebox {
  .polaroid {
    &::before {
      content: url('../img/zoom.svg');
      width: 18px;
      height: 18px;
      position: absolute;
      bottom: 22px;
      right: 20px;
      z-index: 10;
    }
  }
}

.wrapper h1,
.section--home h2 {

  position: relative;
  text-align: center;

  @include mediaquery(l) {
    @include font-size(h1);
    text-align: left;
  }

  svg {
    @include margin(l -45px);
    position: absolute;
    width: 90px;
    height: 90px;
    left: 50%;
    top: -100px;

    @include mediaquery(l) {
      @include margin(l 0);
      width: 60px;
      height: 60px;
      left: -70px;
      top: -5px;
    }

    @include mediaquery(xxl) {
      width: 90px;
      height: 90px;
      left: -100px;
      top: -20px;
    }
  }

}

.wrapper-news {

  .article--news-item {

    @include mediaquery(m) {
      @include margin(l -33px, t -8px);
      @include padding(l 33px, b 1.5);
      border-left: map-get($colors, bordercolor-news) 2px dashed;
    }

    @include mediaquery(xxl) {
      @include margin(l -42px, t -8px);
      @include padding(l 42px, b 1.5);
    }

    h2 {
      position: relative;

      &::after {
        @include background-color(bordercolor-news);
        content: ' ';
        position: absolute;
        display: block;
        top: 10px;
        left: -40px;
        width: 14px;
        height: 14px;
        border-radius: 10px;

        @include mediaquery(m) {
          display: block;
        }

        @include mediaquery(xxl) {
          top: 10px;
          left: -49px;
        }
      }

      img {
        @include margin(l -4px);
        height: 32px;
        vertical-align: -7px;
      }
    }

    &:last-of-type {
      border-left: 0 none;
    }

    &:first-of-type {
      @include padding(t 20px);
      @include margin(t -18px);

      @include mediaquery(xxl) {
        @include margin(t 0);
      }
    }

    &:last-child:first-of-type {
      @include mediaquery(m) {
        border-left: map-get($colors, bordercolor-news) 2px dashed;
      }
    }
  }

  .article--news-item:first-of-type {
    @include padding(t 20px);
  }

  .thedate {
    @include font-size(h2);
    display: block;
  }

}

.gallery {
  @include margin(h -10px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  figure {
    @include margin(b 0);
  }

  &__item {
    @include padding(h 10px, b 20px);
    width: 25%;

    a {
      display: block;
      cursor: pointer;
    }
  }
}
