// font families and their fallbacks for the whole page
// ======================================================================

// you the parameters height (as in line-height) and
// fontface are optional, but you *should* set the default line
// height for the default font.
// if you want to use font-face fonts, define them in this map
// and set the fontface-parameter to true and provide the filename
// for the font-definition to be included (without ../fonts or .woff)

$font-config: (
  default: (
    family: 'Quicksand',
    fallback: 'Helvetica, Arial, sans-serif',
    weight: 400,
    style: normal,
    line-height: 1.5,
    fontface: true,
    file: 'quicksand-regular-webfont'
  ),
  bold: (
    family: 'Quicksand',
    fallback: 'Helvetica, Arial, sans-serif',
    weight: 600,
    style: normal,
    line-height: 1.5,
    fontface: true,
    file: 'quicksand-bold-webfont'
  ),
  light: (
    family: 'Quicksand',
    fallback: 'Helvetica, Arial, sans-serif',
    weight: 300,
    style: normal,
    line-height: 1.5,
    fontface: true,
    file: 'quicksand-light-webfont'
  )
);

// global font sizes scss-map variable - use with mixin
// ======================================================================

$font-sizes: (
  huge: 72px,
  h1: 30px,
  h2: 22px,
  h3: 18px,
  h4: 18px,
  h5: 18px,
  h6: 18px,
  small: 14px,
  default: 18px,
  textmobile: 16px
);
