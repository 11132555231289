// svg icons and custom icon styles
// ======================================================================

// default icon styles - inline, same color, 1em/1em
.icon {
  position: relative;
  top: -0.0625em;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

// set other icon styles here, for example
// .icon--special {
//   @include fill(main);
//   width: $width-special;
//   height: $height-special;
// }
