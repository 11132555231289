// header styles
// ======================================================================

$header-margin: 15px;
$menu-button-size: 60px;

.main-header {
  @include background-color(background);
  position: fixed;
  width: 100%;
  height: $height-header-mobile;
  overflow: hidden;
  box-shadow: map-get($colors, shadow);
  transition: height $duration-menu-toggle ease-in-out;
  z-index: 100;

  @include mediaquery(menubreak) {
    height: $height-header;
  }

  &--menu-open {
    height: 100%;

    @include mediaquery(menubreak) {
      height: $height-header;
    }
  }
}

.header-content-wrap {
  @include padding(a $header-margin);
  @include margin(v 0, h auto);
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: $width-max;

  @include mediaquery(menubreak) {
    flex-direction: row;
  }

  .logo-wrap {
    @include margin(l 0);
    display: block;
    width: 180px;
    height: 80px;

    @include mediaquery(xxl) {
      @include margin(l -8px);
    }

    @include mediaquery(menubreak) {
      @include margin(t 10px);
      width: 237px;
      height: 100px;
    }

    @include attention {
      border-bottom: 0 none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .button--menu {
    position: absolute;
    right: $header-margin;
    top: 2*$header-margin;
    min-width: 0;
    width: $menu-button-size;
    height: $menu-button-size;

    @include mediaquery(menubreak) {
      display: none;
    }

    .icon--menu-close {
      display: none;
    }

    .main-header--menu-open & .icon--menu-close {
      display: inline;
    }

    .main-header--menu-open & .icon--menu-open {
      display: none;
    }

  }

}
