// navigation styles
// ======================================================================

.main-nav {
  @include margin(h -15px, b -15px);
  flex: 1;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    @include mediaquery(menubreak) {
      display: none;
    }
  }

  &::before {
    @include margin(l -419px);
    bottom: -80px;
    left: 50%;
    width: 839px;
    height: 200px;
    background: url('../img/footer-mobile.svg');
    background-size: 839px 200px;

  }

  &::after {
    bottom: 35px;
    right: 15px;
    width: 58px;
    height: 70px;
    background: url('../img/trees-group-three.svg') no-repeat;
    background-size: 58px 70px;
  }
}

.nav-list--header {

  @include margin(a 0);
  @include padding(t 1);
  @include font-size(h2);
  text-align: center;

  @include mediaquery(menubreak) {
    @include padding(t 47px);
    position: absolute;
    right: 0;
    display: inline-block;
  }

  .nav-list__item {
    @include mediaquery(menubreak) {
      @include margin(h 10px);
      display: inline-block;
    }
  }

  .nav-list__item > a {
    @include font-size(h2);
    @include color(default);
    line-height: 2em;
    position: relative;

    @include mediaquery(menubreak) {
      @include padding(t 1);
      @include font-size(h3);
      display: block;
    }

    @include attention {
      @include color(main);
      border-bottom: 0 none;
    }
  }

  .nav-list__item--active > a {
    @include color(main);
    position: relative;

    &::after {
      position: absolute;
      content: ' ';
      display: block;
      background: url('../img/menu-highlight.svg') center center no-repeat;
      background-size: 43px 40px;
      left: -45px;
      top: -6px;
      width: 43px;
      height: 40px;

      @include mediaquery(menubreak) {
        @include margin(l -30px);
        top: -25px;
        left: 50%;
        width: 60px;
        height: 56px;
        background-size: 60px 56px;
      }
    }
  }

}

.nav {

  &--news {
    @include margin(t -4, l 0, b 5);

    @include mediaquery(l) {
      @include margin(t -1, l -70px, b 1.5);
    }

    @include mediaquery(xxl) {
      @include margin(l ($content-indent*(-1)), b 3);
    }

    ul {
      display: flex;
      flex-direction: row;
    }

    ul > li {
      flex: 1;
      text-align: center;
    }

    ul > li:first-child {
      text-align: left;
    }

    ul > li:last-child {
      text-align: right;
    }
  }

}

.main-header--menu-open {
  .main-nav {
    &::before,
    &::after {
      opacity: 1;
    }
  }
}

.pagination {

  ul {
    text-align: center;

    li {
      @include padding(h 0.25);
      display: inline;

      .button {
        @include padding(v 0);
        width: $size-roundbutton;
        height: $size-roundbutton;
        line-height: $size-roundbutton;
        min-width: 0;

        &.active {
          @include background-color(main);
          @include color(inverted);
        }

        &.active::before {
          border-color: map-get($colors, inverted);
        }
      }
    }

  }

}
