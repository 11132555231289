// footer styles
// ======================================================================

.main-footer {
  @include margin(t $height-footer*(-1));
  position: relative;
  width: 100%;
  height: $height-footer;
  overflow: hidden;

  &::before {
    content: ' ';
    width: 1000px;
    height: 148px;
    background-image: url('../img/footer-hill-bg.svg');
    background-position: bottom right;
    background-size: 100% 100%;
    right: 0;
    z-index: 2;

    @include mediaquery(xxxxl) {
      width: 840px;
      height: 124px;
    }
  }

  &::after {
    content: ' ';
    width: 1290px;
    height: 160px;
    background-image: url('../img/footer-hill-fg.svg');
    background-position: bottom left;
    background-size: 100% 100%;
    left: 0;
    z-index: 4;

    @include mediaquery(xxxxl) {
      width: 840px;
      height: 104px;
    }
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;

    background-repeat: no-repeat;
  }

  .icon--trees-group-five,
  .icon--trees-group-three,
  .icon--fox {
    position: absolute;
  }

  .icon--trees-group-five {
    z-index: 5;
    left: 15px;
    top: 35px;
    width: 101px;
    height: 91px;

    @include mediaquery(l) {
      left: 15%;
      top: 0;
      width: 169px;
      height: 151px;
    }

    @include mediaquery(xxxxl) {
      top: 50px;
    }
  }

  .icon--trees-group-three {
    display: none;
    z-index: 3;
    right: 20%;
    top: 25px;
    width: 87px;
    height: 106px;

    @include mediaquery(l) {
      display: block;
    }

    @include mediaquery(xxxxl) {
      top: 70px;
    }
  }

  .icon--fox {
    display: none;
    z-index: 1;
    left: 35%;
    top: 65px;
    width: 53px;
    height: 62px;

    @include mediaquery(l) {
      display: block;
    }

    @include mediaquery(xxxxl) {
      left: 22%;
      top: 125px;
    }
  }

  nav {
    @include padding(t 4.5);
    position: relative;
    z-index: 10;
  }

  .nav-list {

    @include margin(b 0);
    text-align: center;

    &__item {
      @include padding(v 8px);
    }

    &__item > a {
      @include color(inverted);
      @include padding(v 3px);

      @include attention {
        @include color(inverted);
        border-bottom: map-get($colors, inverted) 1px dashed;
      }

      @include mediaquery(xxxxl) {
        @include color(default);

        @include attention {
          @include color(default);
          border-bottom: map-get($colors, default) 1px dashed;
        }
      }
    }

    &--footer {
      @include center;
      max-width: $width-max;

      @include mediaquery(xl) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
      }

      @include mediaquery(xxxl) {
        @include margin(t 2.5);
      }

      li {
        @include padding(h 10px);
      }
    }

  }
}
