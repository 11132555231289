// some basic keyframe animations
// ======================================================================

// default animation, only animate opacity + transform, because
// those are cheap to animate and dont trigger any heavy layout recalc
.animate,
%animate {
  transition: opacity 0.4s ease-in, transform 0.4s ease-in;
}

// define you keyframe animations here and use them anywhere else
// rotates an element once
// @keyframes rotation {
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// }
