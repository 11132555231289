// custom @font-face rules are automatically generated from font-config
// ======================================================================

@if variable-exists(font-config) {
  @each $font-id, $font-definition in $font-config {
    @if map-get($font-definition, fontface) == true {
      $fontfile: map-get($font-definition, file);

      @font-face {
        font-family: map-get($font-definition, family);
        font-weight: map-get($font-definition, weight);
        font-style: map-get($font-definition, style);
        // feel free to add other font-formats here
        // if you need to support older browsers
        src: url('../fonts/#{$fontfile}.woff2') format('woff2'), url('../fonts/#{$fontfile}.woff') format('woff');
      }
    }
  }
}
