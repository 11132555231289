// the colors map variables used throughout the project
// ======================================================================

// define your colors here, and feel free to use 'real' names, just
// like below --> color-red: #fff; but never use those colors in your
// project, only use the map + mixins, where you assing the colors in

$superdarkgreen: #1e3d2e;
$brightgreen: #6b9d06;
$midgreen: #4f7034;
$lightgreen: #e2ebcd;
$orange: #ff6702;
$midorange: #ffe7ac;
$lightorange: #fff2d1;

$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);
$color-red: rgb(255, 0, 0);

$header-shadow: 0 3px 1px rgba(0, 0, 0, 0.07);
$image-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);

$colors: (
  main: $brightgreen,
  default: $superdarkgreen,
  action: $orange,
  background-button: $lightgreen,
  bordercolor-news: $lightgreen,
  background-eyecatcher: $brightgreen,
  bordercolor-eyecatcher: $lightgreen,
  background-member: $lightorange,
  background-donation: $midorange,
  menu-active: $brightgreen,
  error: $color-red,
  inverted: $color-white,
  border: $brightgreen,
  background: $color-white,
  shadow: $header-shadow
);
